export const getTrans = (key: string, fallback?: string, replacements?: object, isPlural?: boolean): string => {
    let translation = key && key.split('.').reduce((t: unknown, i: string) => (t && t[i] !== undefined ? t[i] : null), window.translations);

    if (translation === null || translation === undefined) {
        translation = fallback;
    }

    if (typeof translation === 'object' && 'plural' in translation && 'single' in translation) {
        translation = isPlural ? translation.plural : translation.single;
    }

    if (typeof translation === 'string' && replacements !== undefined && replacements !== null) {
        Object.keys(replacements).forEach((key1) => {
            if (typeof translation === 'string') {
                translation = translation.replace(`:${key1}`, replacements?.[key1]);
            }
        });
    }

    if (typeof translation === 'string') {
        return translation;
    }

    return fallback ?? '';
};
